import React from "react";
import "./style.scss";

//import client images
import salesforce from "../../../images/clients/salesforce.svg";
import boomi from "../../../images/clients/boomi.svg";
import sap from "../../../images/clients/sap.svg";
import workday from "../../../images/clients/workday.svg";
import tibc from "../../../images/clients/tibc.svg";
import sisense from "../../../images/clients/sisense.svg";
import mulesoft from "../../../images/clients/mulesoft.svg";
import alibaba from "../../../images/clients/alibaba.svg";
import qlink from "../../../images/clients/qlink.svg";
import pega from "../../../images/clients/pega.svg";
import logi from "../../../images/clients/logi.svg";

const Clients = () => {
  let clients = [
    { name: "salesforce", logo: salesforce },
    { name: "boomi", logo: boomi },
    { name: "sap", logo: sap },
    { name: "workday", logo: workday },
    { name: "tibc", logo: tibc },
    { name: "sisense", logo: sisense },
    { name: "mulesoft", logo: mulesoft },
    { name: "alibaba", logo: alibaba },
    { name: "qlink", logo: qlink },
    { name: "pega", logo: pega },
    { name: "logi", logo: logi },
  ];
  return (
    <div className="clients__section">
      <h3 className="client-title">
        Les technologies que maîtrisent nos consultants
      </h3>
      <div className="client-row-one">
        {clients.slice(0, 5).map((item) => {
          return (
            <img
              key={item.name}
              width={139}
              height={96}
              src={item.logo}
              alt={item.name}
            />
          );
        })}
      </div>
      <div className="client-row-two">
        {clients.slice(5).map((item) => {
          return (
            <img
              key={item.name}
              width={139}
              height={96}
              src={item.logo}
              alt={item.name}
            />
          );
        })}
      </div>
      <div className="client-row">
        {clients.map((item) => {
          return (
            <img
              key={item.name}
              width={139}
              height={96}
              src={item.logo}
              alt={item.name}
            />
          );
        })}
      </div>
      <h3 className="client-title green">et beaucoup d’autres...</h3>
    </div>
  );
};

export default Clients;
