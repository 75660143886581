import React, { useState } from "react";
import "./style.scss";
import Button from "../elements/button/Button";
import { AiOutlineSlack } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { FaLinkedin } from "react-icons/fa";
import logo from "../../images/logo.svg";

const Footer = () => {
  let [email, setEmail] = useState("");
  return (
    <footer className="app-footer">
      <div className="top-area">
        <h3 className="title">
          Vous souhaitez être recontacté par notre équipe?
        </h3>
        <p className="subtitle">
          Entrez votre adresse email et l’un de nos ingénieurs d’affaires vous
          recontactera dans les 24h.
        </p>
        <div className="footer-input">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Entrez votre email"
          />
          <Button
            onClick={() => {
              setEmail("");
              window.alert("Thank you, we will send you an email shortly");
            }}
            className="footer-btn"
          >
            Get Started
          </Button>
        </div>
        <div className="space"></div>
      </div>
      <div className="bottom-area">
        <div className="top">
          <div className="site-details">
            <img className="site-name" src={logo} alt="logo" />
            <h3 className="site-slug">Sourcing, as a service</h3>
            <a
              target="_blank"
              href="mailto:hello@addinfo.fr"
              className="footer-contact-info"
            >
              <FiMail size={24} /> &nbsp; hello@addinfo.fr
            </a>
            <a
              href="https://addinfo.slack.com/join/shared_invite/zt-ukia2zpw-gWRtExbSBVMZ0NfsD6qn0w"
              target="_blank"
              className="footer-contact-info"
            >
              <AiOutlineSlack size={24} /> &nbsp; Join our slack community
            </a>
            <div className="desktop__copyright">
              <p className="copyright">© 2021 Addinfo. All rights reserved.</p>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/addinfo-fr/about/"
                className="linkedin"
              >
                <FaLinkedin color="#B4B6C4" size={22} />
              </a>
            </div>
          </div>
          <div className="menu-area">
            <div className="menu-item">
              <p className="menu-title">Technologies</p>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/SAP-Addinfo-22dd95bb8a874fdca1cd300e34c9972b"
              >
                SAP
              </a>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Boomi-Addinfo-00952978bddb46689a97112caca238cf"
              >
                Boomi
              </a>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Salesforce-Addinfo-f11dc12f32fe42a685303931a16c69fc"
              >
                Salesforce
              </a>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Cegid-Addinfo-5de6a50f48fd4c07ab3d8469c4aff5eb"
              >
                Cegid
              </a>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Pega-Addinfo-b03cfc35b9364b179798a77d30e09884"
              >
                Pega
              </a>
            </div>
            <div className="menu-item">
              <p className="menu-title">L’entreprise</p>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Notre-histoire-c4477ed8dded4e47af629e29bffb3f96"
              >
                About
              </a>
              <a target="_blank" href="mailto:mb@addinfo.fr">
                Contact
              </a>
            </div>
            <div className="menu-item">
              <p className="menu-title">Autre</p>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Terms-537bf87597814949877e4536b5a6f443"
              >
                Terms
              </a>
              <a
                target="_blank"
                href="https://cooked-brick-c87.notion.site/Privacy-6b45e6cee7354e719355a8c14ee8df41"
              >
                Privacy
              </a>
            </div>
          </div>
        </div>
        <hr className="bottom-separator" />
        <div className="mobile__copyright">
          <p className="copyright">© 2021 Addinfo. All rights reserved.</p>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/addinfo-fr/about/"
            className="linkedin"
          >
            {" "}
            <FaLinkedin color="#B4B6C4" size={22} />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
