import React from "react";
import "./style.scss";
import { StaticImage } from "gatsby-plugin-image";

const Process = () => {
  return (
    <div className="processes_section">
      <p className="green-text">Notre réactivité est notre atout majeur</p>
      <h1 className="title">
        {`Un process simple, \nmême pour des besoins complexes.`}
      </h1>
      <div className="subtitle-cnt">
        <p className="subtitle">
          3 étapes claires, pour vous aider à trouver la perle rare. Qui s’aura
          s’installer durablement chez votre client et délivrer le maximum de
          valeur. Une relation renforcée de bout en bout.
        </p>
      </div>

      <div className="process-list">
        <div className="process-item">
          <StaticImage
            loading="eager"
            src="../../../images/folder.svg"
            alt="scale"
            quality="100"
            width="289"
            placeholder="tracedSVG"
            layout="constrained"
          />
          <h3>Nous reçevons votre fiche de poste et votre budget d’achat</h3>
        </div>
        <div className="process-item">
          <StaticImage
            loading="eager"
            src="../../../images/spyglass.svg"
            alt="scale"
            quality="100"
            width="289"
            placeholder="tracedSVG"
            layout="constrained"
          />
          <h3>
            Nous activons notre réseau de <span>+10k experts</span>
          </h3>
        </div>
        <div className="process-item">
          <StaticImage
            loading="eager"
            src="../../../images/star.svg"
            alt="scale"
            quality="100"
            width="289"
            placeholder="tracedSVG"
            layout="constrained"
          />
          <h3>
            Nous vous envoyons une shortlist de consultants qui{" "}
            <span>correspondent</span>
          </h3>
        </div>
      </div>
    </div>
  );
};
export default Process;
