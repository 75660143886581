import React, { useState } from "react";
import "./style.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

const FaqItem = ({ title, content, id }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      className="faq-item"
      expanded={expanded}
      onChange={handleChange(true)}
    >
      <AccordionSummary
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3 className={expanded ? "expanded" : ""}>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <p>{content}</p>
      </AccordionDetails>
    </Accordion>
  );
};
const Faq = () => {
  const faqlist = [
    {
      id: 1,
      title: "Comment pouvons-nous travailler avec vous?",
      content:
        "Si vous souhaitez travailler avec nous, assurez vous que votre besoin est ouvert à la sous-traitance puis contactez un ingénieur d’affaires. Il s’occupera de votre demande et vous aidera à trouver le candidat idéal.",
    },
    {
      id: 2,
      title:
        "Pouvez-vous trouver des consultants qui feront du présentiel chez mon client?",
      content:
        "Oui, nous adaptons notre recherche selon vos besoins. Ainsi, nous trouvons le candidat idéal pour votre client.",
    },
    {
      id: 3,
      title: "Vous êtes également une ESN?",
      content:
        "Oui, seulement notre modèle est légèrement différent des ESN traditionnelles. Nous n’ouvrons pas de compte, nous sommes concentrés sur la partie sourcing, et rien d’autres. C’est ce qui fait notre force. 100% de notre équipe commerciale travaille sur le même périmètre : le sourcing de candidat et l’enrichissement de notre base de données.",
    },
    {
      id: 4,
      title: "Combien de besoin pouvez-vous gérer à la fois?",
      content:
        "L’optimisation de notre workflow, réparti sur l’ensemble de notre force commerciale, nous permet de répondre à un très grands nombre de besoins.",
    },
    {
      id: 5,
      title: "Comment pouvons-nous commencer?",
      content: `Cliquez sur le bouton "Contacter un ingénieur commercial", puis envoyez-nous un email avec la description du poste et les détails de votre besoin. Un ingénieur d'affaires vous répondra rapidement, puis vous fera parvenir les profils correspondants, disponibles, au tarif souhaité.`,
    },
  ];
  return (
    <div className="faq_section">
      <p className="green-text">Frequently asked questions</p>
      <div className="title-cnt">
        <h1 className="title">Les questions les plus fréquentes</h1>
      </div>
      <div className="faq-list">
        {faqlist &&
          faqlist.map((item) => {
            return (
              <FaqItem
                key={item.id}
                title={item.title}
                content={item.content}
              />
            );
          })}
      </div>
    </div>
  );
};
export default Faq;
