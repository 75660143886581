import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "../elements/button/Button";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";
import logo from "../../images/logo.svg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  appBarTransparent: {
    backgroundColor: "black",
    boxShadow: "none",
  },
  toolbar: theme.mixins.toolbar,
  accordionPaper: {
    backgroundColor: "transparent",
    width: "100%",
  },
  appButton: {
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  appMenu: {
    [theme.breakpoints.up("md")]: { display: "none" },
  },
}));
const Navbar = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const freelanceAccess = () => {
    window.alert(
      "If you already have access to our platform, please use the link we sent you by email"
    );
  };

  return (
    <AppBar position="static" className={classes.appBarTransparent}>
      <Toolbar className={classes.toolbar}>
        <img
          className={"site-name " + classes.appButton}
          src={logo}
          alt="logo"
        />
        <Button
          onClick={freelanceAccess}
          className={`${classes.appButton} nav-btn`}
        >
          Freelance Access
        </Button>
        <Accordion
          className={`${classes.appMenu} ${classes.accordionPaper}`}
          expanded={expanded}
          onChange={handleChange(true)}
        >
          <AccordionSummary
            expandIcon={
              expanded ? (
                <CloseIcon style={{ fill: "white" }} />
              ) : (
                <MenuIcon style={{ fill: "white" }} />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <img className="site-name" src={logo} alt="logo" />
          </AccordionSummary>
          <AccordionDetails>
            <Button onClick={freelanceAccess} className="nav-btn">
              Freelance Access
            </Button>
          </AccordionDetails>
        </Accordion>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
