import React from "react";
import "./style.scss";
import { StaticImage } from "gatsby-plugin-image";

const Map = () => {
  return (
    <div className="map_section">
      <p className="green-text">A Worldwide Network</p>
      <div className="title-cnt">
        <h1 className="title">Un réseau de consultant mondial</h1>
      </div>
      <div className="map-animation">
        <StaticImage
          loading="eager"
          src="../../../images/Map.svg"
          alt="person"
          quality="100"
          placeholder="tracedSVG"
          layout="constrained"
        />
      </div>
      <div className="number__section">
        <div className="number-item">
          <h3>+10k</h3>
          <p>Consultants présents dans notre base de données interne.</p>
        </div>
        <div className="number-item">
          <h3>+30</h3>
          <p>ESNs partenaires en Europe et au UK.</p>
        </div>
        <div className="number-item">
          <h3>14</h3>
          <p>La durée moyenne des missions de nos consultants est de 14 mois</p>
        </div>
        <div className="number-item">
          <h3>+20</h3>
          <p>Actifs dans plus de 20 pays différents</p>
        </div>
      </div>
    </div>
  );
};
export default Map;
