import React from "react";
import "./style.scss";
import { StaticImage } from "gatsby-plugin-image";

const Responsiveness = () => {
  return (
    <div className="response_section">
      <p className="green-text">Notre réactivité est notre atout majeur</p>
      <div className="title-cnt">
        <h1 className="title">
          Parlons de votre besoin aujourd’hui, nous vous envoyons des profils
          demain.
        </h1>
      </div>

      <div className="subtitle-cnt">
        <p className="subtitle">
          Notre offre est un nouveau champs de possibilités pour votre
          entreprise. Cette approche favorise des décisions fortes et un
          alignement durable. Nous co-créerons des plans d'action et resterons
          connectés tout au long de la mission avec vous, afin que le consultant
          s’installe durablement chez votre client.
        </p>
      </div>
      <div className="images-area">
        <div className="single-image">
          <StaticImage
            loading="eager"
            src="../../../images/person.jpg"
            alt="person"
            quality="100"
            height="600"
            width="496"
            placeholder="tracedSVG"
            layout="constrained"
          />
        </div>
        <div className="double-image">
          <StaticImage
            loading="eager"
            src="../../../images/people.jpg"
            alt="people"
            quality="100"
            width="576"
            height="280"
            placeholder="tracedSVG"
            layout="constrained"
          />
          <StaticImage
            loading="eager"
            src="../../../images/person2.jpg"
            alt="person2"
            quality="100"
            width="576"
            height="280"
            placeholder="tracedSVG"
            layout="constrained"
          />
        </div>
      </div>
    </div>
  );
};
export default Responsiveness;
