import React, { createRef, useEffect } from "react";
import animation from "../../../images/header-anime.json";
import "./style.scss";
import lottie from "lottie-web";
import Button from "../../elements/button/Button";

const Header = () => {
  let animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    });
    return () => anim.destroy();
  }, [animationContainer]);

  const contact = () => {
    window.open("mailto:mb@addinfo.fr", "_blank");
  };
  return (
    <header className="header masthead">
      <div className="header-info">
        <div className="text-area">
          <h1 className="header-title">Sourcing, as a service</h1>
          <p className="header-subtitle">
            Nous sommes à l'avant-garde d'une nouvelle approche de l'ingénierie
            commerciale, et nous sommes spécialisés dans le sourcing de top
            candidats partout dans le monde. Nous trouvons les profils, durs à
            sourcer grâce à notre réseau international de +10k experts et grâce
            à un système technologique de pointe.{" "}
          </p>
          <Button onClick={contact} className="header-btn">
            Contacter un Ingénieur d’affaires
          </Button>
        </div>
        <div className="header-animation" ref={animationContainer}></div>
      </div>
    </header>
  );
};

export default Header;
