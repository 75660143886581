import React from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import Header from "../components/landing/header/Header";
import Clients from "../components/landing/clients/Clients";
import Process from "../components/landing/processes/Process";
import Responsiveness from "../components/landing/response/Response";
import Faq from "../components/landing/faq/Faq";
import Map from "../components/landing/map/Map";
import preview from "../images/SocialSharingimage.png";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        description="Nous sommes spécialisés dans le sourcing de top candidats partout dans le monde. Nous trouvons les profils, durs à sourcer grâce à notre réseau international de +10k experts et grâce à un système technologique de pointe. Parlons de votre besoin aujourd’hui, nous vous envoyons des profils demain."
        title="Addinfo | Sourcing as a Service"
        image={preview}
      />
      <Header />
      <Clients />
      <Process />
      <Responsiveness />
      <Faq />
      <Map />
    </Layout>
  );
};

export default IndexPage;
